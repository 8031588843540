<template>
  <div>

    <el-row>
      <el-col
        :lg="{ span: 8, offset: 8 }"
        :md="{ span: 10, offset: 7 }"
        :sm="{ span: 12, offset: 6 }"
        :xs="{ span: 16, offset: 4 }"
      >
        <div class="text-sm-center mb-5">
          <el-image :src="require(`./assets/dacy_public.png`)" fit="contain"></el-image>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col
        :lg="{ span: 8, offset: 8 }"
        :md="{ span: 10, offset: 7 }"
        :sm="{ span: 12, offset: 6 }"
        :xs="{ span: 24, offset: 0 }"
      >
        <el-tabs v-model="tabIndex">
          <el-tab-pane label="Anmelden">
            <p>Bitte melden Sie sich für die Dacy-Knowledgebase an</p>

            <el-row>
              <el-col>
                <el-form label-width="" :model="form">
                  <el-form-item :label-width="110" label="E-Mail Adresse" class="mb-0">
                    <el-input v-model="form.email"></el-input>
                  </el-form-item>
                  <el-form-item :label-width="110" label="Passwort">
                    <el-input type="password" v-model="form.password" :minlength="8"></el-input>
                  </el-form-item>
                  <el-form-item class="text-sm-center">
                    <el-button class="w-100" type="primary" @click="submitForm()"
                      >Anmelden</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="Registrieren">
            <el-row>
              <el-col>
                <el-form label-width="" :model="form">
                  <el-form-item :label-width="110" label="Anrede" class="mb-0">
                    <el-select v-model="form.salutation" class="w-100">
                      <el-option value="Frau">Frau</el-option>
                      <el-option value="Herr">Herr</el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label-width="110" label="Titel" class="mb-0">
                    <el-select v-model="form.title" class="w-100">
                      <el-option value="kein">kein</el-option>
                      <el-option value="Dr.">Dr.</el-option>
                      <el-option value="Prof.">Prof.</el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label-width="110" label="Vorname" class="mb-0">
                    <el-input v-model="form.firstname"></el-input>
                  </el-form-item>
                  <el-form-item :label-width="110" label="Nachname" class="mb-0">
                    <el-input v-model="form.lastname"></el-input>
                  </el-form-item>
                  <el-form-item :label-width="110" label="Beschäftigung" class="mb-0">
                    <el-select v-model="form.position" class="w-100">
                      <el-option value="Assistenzarzt/-ärztin, Krhs">Assistenzarzt/-ärztin, Krhs</el-option>
                      <el-option value="Oberarzt/-ärztin, Krhs.">Oberarzt/-ärztin, Krhs.</el-option>
                      <el-option value="Chefarzt/-ärztin, Leitender Arzt/Ärztin, Krhs.">Chefarzt/-ärztin, Leitender Arzt/Ärztin, Krhs.</el-option>
                      <el-option value="Ausbildungsassistent/-in, Praxis/MVZ">Ausbildungsassistent/-in, Praxis/MVZ</el-option>
                      <el-option value="Arzt/Ärztin, Praxis/MVZ">Arzt/Ärztin, Praxis/MVZ</el-option>
                      <el-option value="andere Beschäftigung">andere Beschäftigung</el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label-width="110" label=" " class="mb-0">
                    <el-radio-group v-model="form.status">
                      <el-radio label="selbständig">Selbständig</el-radio>
                      <el-radio label="angestellt">Angestellt</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item :label-width="110" label="EFN" class="mb-0">
                    <el-input v-model.trim="form.efn"></el-input>
                  </el-form-item>
                  <hr/>
                  <el-form-item :label-width="110" label="E-Mail Adresse">
                    <el-input v-model.trim="form.email"></el-input>
                  </el-form-item>
                  <h6>Gewünschtes Passwort vergeben</h6>

                  <el-form-item :label-width="110" label="Passwort" class="mb-0">
                    <el-input type="password" v-model.trim="form.password" :minlength="8"></el-input>
                  </el-form-item>
                  <h6>Erneut eingeben</h6>
                  <el-form-item :label-width="110" label="Passwort" class="mb-0">
                    <el-input type="password" v-model.trim="form.password_repeat" :minlength="8"></el-input>
                  </el-form-item>
                  <el-form-item class="text-sm-center">
                    <el-button class="w-100" type="primary" @click="submitRegisterForm()"
                    >Registrieren</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>

          </el-tab-pane>
          <el-tab-pane label="Passwort vergessen">

            <div v-if="showResetPasswordForm">
              <p style="word-break: keep-all">Bitte vergeben Sie nun ein neues Passwort:</p>
              <el-row>
                <el-col>
                  <el-form label-width="" :model="form">
                  <h6>Gewünschtes Passwort</h6>
                  <el-form-item :label-width="110" label="Passwort" class="mb-0">
                    <el-input type="password" v-model.trim="form.password" :minlength="8"></el-input>
                  </el-form-item>
                  <h6>Erneut eingeben</h6>
                  <el-form-item :label-width="110" label="Passwort" class="mb-0">
                    <el-input type="password" v-model.trim="form.password_repeat" :minlength="8"></el-input>
                  </el-form-item>
                  <el-form-item class="text-sm-center">
                    <el-button class="w-100" type="primary" @click="submitNewPasswordForm()"
                    >Passwort setzen</el-button>
                  </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </div>
            <div v-else>
              <p style="word-break: keep-all">Mit welcher E-Mail Adresse haben Sie sich registriert? Wir schicken Ihnen einen Link um das Passwort neu setzen zu können.</p>
              <el-row>
                <el-col>
                  <el-form label-width="" :model="form">
                    <el-form-item :label-width="110" label="E-Mail Adresse">
                      <el-input v-model="form.email"></el-input>
                    </el-form-item>
                    <el-form-item class="text-sm-center">
                      <el-button class="w-100" type="primary" @click="submitPasswordResetForm()"
                      >E-Mail senden</el-button
                      >
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </div>

          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from "axios";

export default {
  name: 'PublicLogin',
  props: [],
  components: {},
  data() {
    return {
      tabIndex: "0",
      form: {
        email: '',
        password: '',
        password_repeat: '',
        status: '',
        salutation: '',
        firstname: '',
        lastname: '',
        position: '',
        title: '.',
        efn: ''
      },
      loginResult: null,
      showResetPasswordForm: false,
      // eslint-disable-next-line
      emailRegEx: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    }
  },
  mounted: function () {

    if(this.$route.query && this.$route.query.token) {
      this.showResetPasswordForm = true
      this.tabIndex = "2"
    }

  },
  computed: {
    ...mapGetters('config', ['route_name_after_login']),
  },
  /*
  watch: {
    var: function (val) {
    }
  },
  */

  methods: {
    async submitNewPasswordForm() {

      if (this.form.password === '' || this.form.password !== this.form.password_repeat) {
        this.$notify.error({
          message: "Die Passwörter stimmen nicht überein",
          position: 'bottom-left',
        })
        return
      }

      try {
        await axios.post('/public/auth/reset/confirm', { ...this.form, token: this.$route.query.token })
        this.$notify.success({
          message: "Passwort erfolgreich geändert, Sie können sich nun anmelden",
          position: 'bottom-left',
        })
        this.form.password = ''
        this.form.password_repeat = ''
        this.tabIndex = "0"

      }
      catch (e) {
        this.$notify.error({
          message: "Der Link ist abgelaufen, bitte Vorgang erneut starten.",
          position: 'bottom-left',
        })
        this.showResetPasswordForm = false
      }
    },
    async submitPasswordResetForm() {

      if(!this.emailRegEx.test(this.form.email)) {
        this.$notify.error({
          message: "Bitte eine gültige E-Mail Adresse eingeben",
          position: 'bottom-left',
        })
      }

      let diagnosis_id = ''
      if(this.$route.query && this.$route.query.d) {
        diagnosis_id = this.$route.query.d
      }

      try {
        await axios.post('/public/auth/reset', { ...this.form, diagnosis_id})
        this.form.email = ''
        this.$notify.success({
          message: "Bitte prüfen Sie Ihre Mails",
          position: 'bottom-left',
        })
      }
      catch (e) {
        this.$notify.error({
          message: "Unbekannte E-Mail Adresse",
          position: 'bottom-left',
        })
      }

    },
    submitForm() {

      if(!this.emailRegEx.test(this.form.email) || this.form.password === '') return

      this.$auth
        .login({
          data: this.form,
          url: 'public/auth/login'
        })
        .then((result) => {
          this.loginResult = result.data
          this.processLogin()
        })
        .catch(() => {
          this.$notify.error({
            message: 'Ihre eingegebenen Zugangsdaten waren nicht korrekt',
            position: 'bottom-left',
          })
        })
    },
    EFNisValid() {
      if(this.form.efn === '') return false

      let efn = this.form.efn.replace(/ /g, '')

      var len = efn.length;
      var parity = len % 2;
      var sum = 0;
      for (var i = len-1; i >= 0; i--)
      {
        var d = parseInt(efn.charAt(i));
        if (i % 2 == parity) { d *= 2 }
        if (d > 9) { d -= 9 }
        sum += d;
      }
      const efnresult = (sum % 10).toString();
      return (efnresult == 0)
    },
    submitRegisterForm: async function () {

      let errormsg = null

      if (this.form.firstname === '') errormsg = 'Bitte Vornamen eingeben'
      else if (this.form.lastname === '') errormsg = 'Bitte Nachnamen eingeben'
      else if (this.form.salutation === '') errormsg = 'Bitte eine Anrede eingeben'
      else if (this.form.status === '') errormsg = 'Sind Sie selbständig oder angestellt tätig?'
      else if (this.form.position === '') errormsg = 'Bitte Position angeben'
      else if (!this.EFNisValid()) errormsg = 'Bitte gültige EFN eingeben'
      else if (!this.emailRegEx.test(this.form.email)) errormsg = 'Bitte eine gültige E-Mail Adresse eingeben'
      else if (this.form.password === '') errormsg = 'Bitte ein Passwort eingeben'
      else if (this.form.password !== this.form.password_repeat) errormsg = 'Die Passwörter stimmen nicht überein'

      if (errormsg) {
        this.$notify.error({
          message: errormsg,
          position: 'bottom-left',
        })
      } else {
        try {
          await axios.post('/public/auth/register', this.form)

          this.form = {
              email: '',
              password: '',
              status: '',
              salutation: '',
              firstname: '',
              lastname: '',
              position: '',
              title: '.',
              efn: '',
              password_repeat: ''
          }

          this.$notify.success({
            message: "Vielen Dank! Sie können sich nun anmelden",
            position: 'bottom-left',
          })

        } catch (e) {
          this.$notify.error({
            message: "Ein Fehler trag bei der Registrierung auf, bitte wenden Sie sich an info@dacy.pro",
            position: 'bottom-left',
          })
        }
      }
    },
    processLogin() {
      this.$auth.token('dacy-token', this.loginResult.token.token)
      this.$auth.user(this.loginResult.user)

      var r = new URL(location.href);
      r.searchParams.delete("token");
      location.href = r.href
    },
  },
}
</script>
<style>
body {
  background-color: #fff;
}
</style>
